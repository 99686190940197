

















import { Vue, Component, VModel } from 'vue-property-decorator'
import { Freq } from '@tada-team/tdproto-ts'

@Component({
  components: {
    TdInputNumber: () => import('td-ui/dist/TdInputNumber.vue'),
  },
})
export default class RepeatabilityFrequency extends Vue {
  @VModel({
    required: true,
    type: Object,
  }) freq!: Freq

  private async updateFrequency (val: number) {
    if (!this.freq || val > 100) return
    this.freq.frequency = !val ? 1 : val
    this.$emit('updateFrequencyDescription')
  }

  private inputKeyDown (event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault()
    }
  }

  private get isDaily () {
    return this.freq.repeatabilityType === 'MEETING_PRESENCE_STATUS_DAILY'
  }

  private get everyTitle () {
    return this.isDaily
      ? this.$t('meetings.repeatability.daily.every').toString()
      : this.$t('meetings.repeatability.weekly.every').toString()
  }

  private get title () {
    return this.isDaily
      ? this.$t('meetings.repeatability.daily.day').toString()
      : this.$t('meetings.repeatability.weekly.week').toString()
  }
}
